<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Mentors In Practise Sessions"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template v-slot:right-actions>
            <BaseButton
              text="Create a Session"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              @click="editClass()"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto">
        <EditClass
          class="tw-mx-auto"
          @class-created="classCreated()"
          :class-data="classData"
        />
      </div>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">

        <!-- ACTIVE CLASSES -->

        <p class="tw-font-bold opacity-78 leading-19 tw-text-base tw-mb-2">Active Sessions</p>

        <transition name="fade" mode="out-in" appear>

          <div v-if="errorActiveClasses" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorActiveClasses"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingActiveClasses"
            :list-length="4"
            class="tw-mt-16-- --tw-max-w-list --tw-mx-auto"
          />

          <div v-else-if="!activeClasses.length" class="tw-mb-12 tw-text-center">
            <p class="tw-py-8 tw-text-base tw-text-center">No sessions.</p>
            <BaseButton
              text="Create a Class"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              @click="editClass()"
            />
          </div>

          <ViewMore
            v-else
            class="tw-mb-12"
            :list="activeClasses"
            :limit="4"
          >
            <template v-slot="{ item: aClass, index }">
              <div
                :key="`class-${aClass.id}`"
                :class="[
                  'tw-flex tw-items-center tw-justify-between-- tw--mx-4 tw-px-4 tw-py-4 tw-group hover:tw-bg-app-white-87 hover:tw-shadow-app transition --tw-cursor-pointer',
                  { 'tw-bg-app-white-32': (((index % 2) === 0)) },
                ]"
              >
                <div class="tw-inline-block tw-w-1/4">
                  <p class="tw-uppercase tw-truncate tw-text-base">{{ aClass.class }}</p>
                </div>

                <div class="tw-inline-block tw-ml-4">
                  <p class="tw-uppercase tw-truncate tw-text-sm tw-mb-2 font-nunito" v-if="aClass.start && aClass.start !== 'undefined'">START: {{ aClass.start | toJsDate | moment('Do, MMM YYYY') }}</p>
                  <p class="tw-uppercase tw-truncate tw-text-sm font-nunito" v-if="aClass.end && aClass.end !== 'undefined'">END: {{ aClass.end | toJsDate | moment('Do, MMM YYYY') }}</p>
                </div>

                <div class="tw-flex-grow"></div>

                <div class="tw-inline-flex tw-items-center opacity-31 group-hover:tw-opacity-100 transition">
                  <BaseButton
                    text="edit"
                    :title="`edit ${aClass.class}`"
                    icon
                    @click="editClass(aClass)"
                    class="tw-mr-2"
                  >
                    <svg version="1.1" slot="icon" class="my-btn__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                        l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                        M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                        c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                    </svg>
                  </BaseButton>
                  <BaseButton
                    text="view more"
                    :title="`view ${aClass.class}`"
                    :to="{ name: 'viewClass', params: { id: aClass.id } }"
                    class="tw-mr-2"
                  />
                </div>
              </div>
            </template>
          </ViewMore>

        </transition>

        <!-- ENDED CLASSES -->

        <p class="tw-font-bold opacity-78 leading-19 tw-text-base tw-mb-2">Ended Sessions</p>

        <transition name="fade" mode="out-in" appear>

          <div v-if="errorEndedClasses" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorEndedClasses"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingEndedClasses"
            :list-length="6"
            class="tw-mt-16 -tw-max-w-list tw-mx-auto"
          />

          <div v-else-if="!endedClasses.length" class="tw-my-12 tw-text-center">
            <p class="tw-py-8 tw-text-base tw-text-center">No sessions.</p>
          </div>

          <ViewMore
            v-else
            class=""
            :list="endedClasses"
            :limit="8"
          >
            <template v-slot="{ item: aClass, index }">
              <div
                :key="`class-${aClass.id}`"
                :class="[
                  'tw-flex tw-items-center tw-justify-between-- tw--mx-4 tw-px-4 tw-py-4 hover:tw-bg-app-white-87 hover:tw-shadow-app transition --tw-cursor-pointer',
                  { 'tw-bg-app-white-32': (((index % 2) === 0)) },
                ]"
              >
                <div class="tw-inline-block tw-w-1/4">
                  <p class="tw-uppercase tw-truncate tw-text-base">{{ aClass.class }}</p>
                </div>

                <div class="tw-inline-block tw-ml-4">
                  <p class="tw-uppercase tw-truncate tw-text-sm tw-mb-2 font-nunito">START: {{ aClass.start | toJsDate | moment('Do, MMM YYYY') }}</p>
                  <p class="tw-uppercase tw-truncate tw-text-sm font-nunito">END: {{ aClass.end | toJsDate | moment('Do, MMM YYYY') }}</p>
                </div>

                <div class="tw-flex-grow"></div>

                <div class="tw-inline-flex tw-items-center">
                  <!-- <BaseButton
                    text="edit"
                    :title="`edit ${aClass.class}`"
                    icon
                    @click="editClass(aClass)"
                    class="tw-mr-2"
                  >
                    <svg version="1.1" slot="icon" class="my-btn__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                        l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                        M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                        c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                    </svg>
                  </BaseButton> -->
                  <BaseButton
                    text="view more"
                    :title="`view ${aClass.class}`"
                    :to="{ name: 'viewClass', params: { id: aClass.id } }"
                    class="tw-mr-2"
                  />
                </div>
              </div>
            </template>
          </ViewMore>

        </transition>
      </div>
    </div>
  </AppView>
</template>

<script>
import EditClass from '@/views/Classes/_partials/EditClass.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';

export default {
  name: 'ClassesView',
  components: {
    EditClass,
    ContentLoadingFullWidth,
  },
  data() {
    return {
      activeClasses: [],
      endedClasses: [],
      classData: undefined, // changes to a class' obj data if a class is being edited
      loadingActiveClasses: true,
      loadingEndedClasses: true,
      errorActiveClasses: undefined,
      errorEndedClasses: undefined,
    };
  },
  methods: {
    /**
     * combines two almost similar methods
     *  - getActiveClasses() and getEndedClasses()
     * must be capitalized
     */
    async getTypeClasses(type = '') {
      const classVar = `${type.toLowerCase()}Classes`;

      this[`loading${type}Classes`] = true;
      this[`error${type}Classes`] = undefined;
      await this.$nextTick();

      const classes = await this.$store.dispatch('getTypeClasses', type);

      if (!classes) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this[`loading${type}Classes`] = false;
        this[`error${type}Classes`] = `We could not get the list of ${type} sessions. Please try again later`;

        return false;
      }

      await this.$nextTick();

      this[classVar] = classes;

      await this.$nextTick();
      this[`loading${type}Classes`] = false;
      this[`error${type}Classes`] = undefined;

      return true;
    },
    async classCreated() {
      // close modal then get classes
      this.closeModal();

      await Promise.all([
        this.getTypeClasses('Active'),
        this.getTypeClasses('Ended'),
      ]);
    },
    async editClass(classData = {}) {
      this.classData = classData;
      await this.$nextTick();

      this.openEditClassModal();
    },
    openEditClassModal() {
      this.$store.commit('openModal', [true, 'full-screen']);
    },
  },
  async created() {
    await this.$nextTick();

    await Promise.all([
      this.getTypeClasses('Active'),
      this.getTypeClasses('Ended'),
    ]);
  },
};
</script>

<style>

</style>
