<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          type="back"
          show-back-button
          :prev-route="{ name: 'sessions' }"
          :title="classData.class || 'fetching session...'"
          :title-class="[
            { 'ml-body opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !loadingClass },
            { 'opacity-31 tw-italic tw-lowercase': loadingClass },
          ]"
        />
      </transition>
    </template>

    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto" v-if="editingClass">
        <EditClass
          class="tw-mx-auto"
          @class-created="classEdited()"
          :class-data="classData"
        />
      </div>

      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto" v-if="addingStudent">
        <AddStudent
          class="tw-mx-auto"
          @student-added="studentAdded()"
          :class-id="classData.id"
        />
      </div>
    </portal>

    <!-- CLASS DETAILS -->
    <div class="app-view-grid px-body">
      <div class="app-view-grid__col-74">
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorClass" class="tw-mt-12" key="errmsg">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorClass"></p>
          </div>

          <div v-else-if="loadingClass" class="tw-mt-12" key="lodng">
            <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
          </div>

          <div v-else key="bdy">
            <transition name="fade" mode="out-in" appear>

              <div class="tw-rounded-32 tw-border-t-2 tw-border-app-white-87 tw-p-8 tw-shadow-app tw-mb-8 tw-mt-6 transition-slow">
                <h4 class="tw-text-base tw-uppercase tw-mb-4">{{ classData.class }}</h4>
                <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">Description</h5>
                <h4 class="tw-text-base tw-mb-4 tw-w-3/4">{{ classData.description }}</h4>
                <div class="tw-flex tw-mb-8">
                  <div class="tw-inline-block tw-mr-8">
                    <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">Start Date</h5>
                    <h4 class="tw-text-base">{{ classData.start | toJsDate | moment('Do, MMM YYYY') }}</h4>
                  </div>
                  <div class="tw-inline-block">
                    <h5 class="tw-text-sm tw-leading-tight tw-uppercase tw-mb-1 tw-font-bold opacity-54">End Date</h5>
                    <h4 class="tw-text-base">{{ classData.end | toJsDate | moment('Do, MMM YYYY') }}</h4>
                  </div>
                </div>

                <div class="tw-flex tw-items-center" v-if="Number(classData.status) === 0">
                  <BaseButton
                    text="edit session"
                    :title="`edit ${classData.class}`"
                    icon
                    @click="openEditClassModal()"
                    class="tw-mr-2"
                  >
                    <svg version="1.1" slot="icon" class="my-btn__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                      <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                        l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                        M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                        c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                    </svg>
                  </BaseButton>
                  <BaseButton
                    text="end session"
                    :title="`end ${classData.class}`"
                    confirm
                    :confirm-message="`You are about to end the session <span class='tw-font-bold'>${classData.class}</span>.<br>${classList.length ? 'There are still students in the session.<br>' : ''}Are you sure?`"
                    :disabled="!canEndClass"
                    @click="endClass()"
                    class="tw-mr-2"
                  />
                </div>
              </div>

            </transition>
          </div>

        </transition>
      </div>
    </div>

    <!-- CLASS LIST -->
    <div class="app-view-grid px-body">
      <div class="app-view-grid__col-74">

        <div class="tw-flex tw-items-center tw-justify-between tw-mb-4">
          <h4 class="tw-text-base tw-font-bold">Students in this session</h4>

          <BaseButton
            text="add a student"
            v-if="Number(classData.status) === 0"
            title="add a student in the session"
            icon
            @click="openAddStudentModal()"
            class="tw-border-none tw-bg-app-deep-blue-11"
          >
            <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M69.3,33.3H38.7V2.7C38.7,1.1,37.6,0,36,0s-2.7,1.1-2.7,2.7v30.5H2.7C1.1,33.3,0,34.4,0,36s1.1,2.7,2.7,2.7h30.5v30.5
                c0,1.6,1.1,2.7,2.7,2.7c1.6,0,2.7-1.1,2.7-2.7V38.7h30.5c1.6,0,2.7-1.1,2.7-2.7C72,34.4,70.9,33.3,69.3,33.3z"/>
            </svg>
          </BaseButton>
        </div>

        <transition name="fade" mode="out-in" appear>

          <div v-if="errorClassList" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorClassList"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingClassList"
            class="tw-py-16 -tw-max-w-list tw-mx-auto"
            :list-length="4"
          />

          <template v-else>

            <div v-if="classList.length === 0" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">Not found</p>
            </div>

            <AccountsList
              v-else
              searchable
              :list="classList"
            >
              <template v-slot:account-actions="{ item: user }">
                <!-- CERTIFY BUTTON -->
                <p v-if="user.course">certified</p>
                <BaseButton
                  v-else-if="Number(classData.status) === 0"
                  text="Certify"
                  :title="`certify ${user.name}`"
                  confirm
                  class="tw-bg-app-deep-blue opacity-31 group-hover:tw-opacity-100 hover:tw-opacity-100 tw-rounded-full tw-border-none tw-py-3 tw-px-6 tw-text-white hover:tw-shadow-app-lg transition-fast"
                  @click="certifyUser(user)"
                />
              </template>
            </AccountsList>

          </template>

        </transition>

      </div>
    </div>

  </AppView>
</template>

<script>
import EditClass from '@/views/Classes/_partials/EditClass.vue';
import AddStudent from '@/views/Classes/_partials/AddStudent.vue';

import AccountsList from '@/views/_partials/AccountsList.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';

export default {
  name: 'ClassesView',
  components: {
    EditClass,
    AddStudent,
    AccountsList,
    ContentLoadingFullWidth,
  },
  props: ['id'],
  data() {
    return {
      loadingClass: true,
      errorClass: undefined,
      loadingClassList: true,
      errorClassList: undefined,
      editingClass: false,
      addingStudent: false,
      classData: {}, // class object
      classList: [],
    };
  },
  computed: {
    canEndClass() {
      return true;
    },
  },
  methods: {
    async getClass() {
      //
      this.loadingClass = true;
      this.errorClass = undefined;

      await this.$nextTick();

      const classData = await this.$store.dispatch('getClass', this.id);

      if (!classData) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingClass = false;
        this.errorClass = 'We could not get the class. Please try again later';

        return;
      }

      await this.$nextTick();

      this.classData = { ...classData };

      this.loadingClass = false;
      this.errorClass = undefined;
    },
    async getClassList() {
      //
      this.loadingClassList = true;
      this.errorClassList = undefined;

      await this.$nextTick();

      const classList = await this.$store.dispatch('getClassList', this.id);

      if (!classList) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingClass = false;
        this.errorClass = 'We could not get the session. Please try again later';

        return;
      }

      await this.$nextTick();

      this.classList = classList;

      this.loadingClassList = false;
      this.errorClassList = undefined;
    },
    async openEditClassModal() {
      this.editingClass = true;
      this.addingStudent = false;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'full-screen']);
    },
    async openAddStudentModal() {
      this.addingStudent = true;
      this.editingClass = false;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'modal']);
    },
    async classEdited() {
      // close modal then get classes
      this.closeModal();
      this.getClass();
      await this.$nextTick();

      this.editingClass = false;
    },
    async endClass() {
      const result = await this.$store.dispatch('endClass', this.classData.id);

      if (!result) {
        this.$toasted.global.appError();
        await this.$nextTick();
        return;
      }

      await this.$nextTick();
      await this.getClass();
      await this.$nextTick();
    },
    async studentAdded() {
      // close modal then get classes
      this.closeModal();
      this.getClassList();
      await this.$nextTick();

      this.addingStudent = false;
    },
    async certifyUser(user) {
      //
      // console.log(user);
      const result = await this.$store.dispatch('certifyStudent', user.id);

      if (!result) {
        this.$toasted.global.appError();
        await this.$nextTick();
        return;
      }

      await this.$nextTick();
      await this.getClassList();
      await this.$nextTick();
    },
  },
  async created() {
    await this.$nextTick();

    await Promise.all([
      this.getClass(),
      this.getClassList(),
    ]);
  },
};
</script>

<style>

</style>
