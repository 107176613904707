<template>
  <!-- FORM -->
  <!-- COMPANYSIGNUP -->
  <div
    v-if="companyCreated && isSuperAdmin"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
  >
    <div class="tw-w-full">
      <div class="tw-px-6 tw-py-10 tw-block tw-bg-app-light-blue tw-mb-4">
        <h4 class="tw-text-base">Company Created</h4>
        <h1 class="tw-text-4xl font-title">Step 2. Create an admin for {{ companyForm.name }}</h1>

        <BaseButton
          text="Create a staff admin"
          :to="{ name: 'accounts', query: { create: staffAccountId } }"
          :class="[
            'tw-py-3 tw-px-8 tw-mt-4',
            `${btnClass}`
          ]"
        />
      </div>
    </div>
  </div>

  <form
    id="signup-form"
    ref="signup-form"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/company/signup"
    @submit.prevent="onSubmit"
    v-else-if="isSuperAdmin"
  >

    <div class="tw-w-full">
      <div class="tw-h-8 tw-block"></div>
      <h1 class="tw-text-4xl font-title">Create a company <span class="opacity-54">( step 1 )</span></h1>
      <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6>
    </div>

    <template>
      <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">1. Company details</h2>
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="Company name"
          name="name"
          type="text"
          placeholder="Company name"
          input-classes="tw-p-3"
          input-type=""
          v-model="companyForm.name"
          :error="errors.name"
        />
      </div>
      <div class="tw-w-full tw-mb-2 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="email"
          instruct="main company's email address"
          name="email"
          type="email"
          placeholder="email address"
          input-classes="tw-p-3"
          v-model="companyForm.email"
          :error="errors.email"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="address"
          name="address"
          type="text"
          placeholder="Address"
          input-classes="tw-p-3"
          input-type=""
          v-model="companyForm.address"
          :error="errors.address"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          instruct="where app will be hosted. <br>DO NOT include 'https://'"
          name="website"
          type="text"
          placeholder="Website url"
          input-classes="tw-p-3"
          input-type=""
          v-model="companyForm.website"
          :error="errors.website"
        />
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <InputGroup
          label="Phone number"
          name="phone"
          type="number"
          placeholder="07********"
          instruct="Use the pattern 07********"
          input-classes="tw-p-3"
          v-model="companyForm.phone"
          :error="errors.phone"
        />
      </div>
      <!-- <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="County"
          name="county"
          instruct="Type to find your county"
          nameKey="county_name"
          placeholder="County"
          input-classes="my-select--huge tw-bg-gray-200"
          v-model="companyForm.county"
          :error="errors.county"
          :options="countyList"
          :reduce="county => county.id"
          :selected="companyForm.county"
          :clearable="false"
          filterable
          searchable
        />
      </div> -->
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          label="Country"
          name="country"
          instruct="Type to find your country"
          nameKey="country_name"
          placeholder="Country"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="companyForm.country"
          :clearable="false"
          :options="countryList"
          :reduce="country => country.id"
          :selected="companyForm.country"
          :error="errors.country"
          filterable
          searchable
        />
      </div>
    </template>

    <!-- LICENSE INPUTS -->
    <template>

      <div class="tw-w-full tw-pt-16 tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <h2 class="font-title tw-text-xl tw-mb-1 tw-text-left">2. License details</h2>
      </div>
      <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
        <SelectGroup
          :class="{ '--loading-data': loadingForm }"
          label="License Type"
          name="license"
          nameKey="license_type"
          placeholder="License Type"
          input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
          v-model="companyForm.license"
          :options="licenseList"
          :reduce="license => license.id"
          :selected="companyForm.license"
          :clearable="false"
          :error="errors.license"
        />
      </div>

    </template>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-20">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
/* eslint-disable func-names */

import form from '@/modules/formHelper';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import { apiGet, apiPost } from '@/modules/apiHelper';

export default {
  name: 'CreateCompany',
  components: {
    InputGroup,
    SelectGroup,
  },
  props: {
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: false,
      btnText: 'Create Company',
      btnDisabled: false,
      companyCreated: false,

      companyForm: {
        // text inputs
        name: undefined,
        website: undefined,
        address: undefined,
        // number
        phone: undefined,
        // email
        email: undefined,
        // selects
        county: undefined,
        country: undefined,
        license: undefined,
      },
      // company admin
      admin: undefined,
      adminList: [],
      countyList: [],
      countryList: [],
      licenseList: [],
      // loaded on Created
      errors: {},
    };
  },
  watch: {
    'companyForm.name': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.name = 'invalid Company name';
      } else {
        this.errors.name = undefined;
      }
    },
    'companyForm.address': function (val) {
      if (!form.isValidTextInput(val)) {
        this.errors.address = 'invalid address';
      } else {
        this.errors.address = undefined;
      }
    },
    'companyForm.website': function (val) {
      if (!form.isValidUrl(val, true)) {
        this.errors.website = 'invalid website url';
      } else {
        this.errors.website = undefined;
      }
    },
    // #region number watchers
    'companyForm.phone': function (val) {
      if (!form.isValidNumber(val)) {
        this.errors.phone = 'invalid Phone number';
      } else {
        this.errors.phone = undefined;
      }
    },
    'companyForm.email': function (val) {
      if (!form.isValidEmail(val)) {
        this.errors.email = 'invalid email address';
      } else {
        this.errors.email = undefined;
      }
    },
    // #region select watchers
    'companyForm.county': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.county = 'County is required';
      } else {
        this.errors.county = undefined;
      }
    },
    'companyForm.country': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.country = 'Country is required';
      } else {
        this.errors.country = undefined;
      }
    },
    'companyForm.license': function (val) {
      if (!form.isValidSelectInput(val)) {
        this.errors.license = 'License is required';
      } else {
        this.errors.license = undefined;
      }
    },
  },
  computed: {
    staffAccountId() {
      return this.$store.state.User.accountTypes.staff;
    },
    canSubmit() {
      return form.isValidTextInput(this.companyForm.name)
        && form.isValidUrl(this.companyForm.website, true)
        && form.isValidTextInput(this.companyForm.address)
        && form.isValidNumber(this.companyForm.phone)
        && form.isValidEmail(this.companyForm.email)
        && form.isValidSelectInput([
          // this.county,
          this.companyForm.country,
          this.companyForm.license,
        ]);
    },
  },
  methods: {
    formData() {
      const formData = new FormData();

      formData.set('name', this.companyForm.name);
      formData.set('phone', this.companyForm.phone);
      formData.set('email', this.companyForm.email);
      formData.set('website', this.companyForm.website);
      formData.set('address', this.companyForm.address);
      // formData.set('county', this.companyForm.county);
      formData.set('country', this.companyForm.country);
      formData.set('license', this.companyForm.license);

      return formData;
    },
    onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      apiPost('create_company', this.formData())
        .catch((err) => {
          console.error('create error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'Create Company';
          if (!res) return false;

          // eslint-disable-next-line prefer-destructuring
          const data = res.data;

          if (data.error) {
            console.error(data.message);

            this.$toasted.global.appError({
              errorMessage: data.message,
              duration: 4000,
              position: 'top-right',
            });

            return false;
          }

          this.$toasted.info('Success!', {
            position: 'bottom-left',
            fullWidth: true,
            duration: 3300,
          });


          if (!this.admin) {
            this.companyCreated = true;
          } else {
            this.$emit('create-success', this.companyForm);
          }


          return true;
        });

      return true;
    },

    /**
     * @returns {Array} [[...xhr requests], [...response names], [...data names]]
     */
    async prepareFormRequests() {
      const companyFormRequests = [
        // ['county', 'countyList'],
        ['countries', 'countryList', 5, 'users'],
      ];
      const licenseRequests = [
        ['license_group', 'licenseList'],
      ];


      /**
       *
       * each request has three parts
       *
       * [
       *
       *    xhr request -> apiGet(endpoint),
       *
       *    name of where to get the data in the xhr response -> res.data[name],
       *    usually is same as the endpoint
       *
       *    data name to add the results to -> this.[dataName]
       * ]
       *
       * #### the requests are mapped to three arrays
       * [[...xhr requests], [...response names], [...data names]]
       *
       */
      const req = [
        [
          ...companyFormRequests.map((r) => apiGet(r[0], 5)),
          ...licenseRequests.map((r) => apiGet(r[0])),
        ],
        [
          ...companyFormRequests.map((r) => r[3] || r[0]),
          ...licenseRequests.map((r) => r[3] || r[0]),
        ],
        [
          ...companyFormRequests.map((r) => r[1]),
          ...licenseRequests.map((r) => r[1]),
        ],
      ];

      return req;
    },

    async loadForm() {
      this.loadingForm = true;
      this.errorForm = undefined;
      this.companyCreated = false;

      const formRequests = await this.prepareFormRequests();

      await Promise.all(formRequests[0])
        .catch((err) => {
          console.warn(err);
          this.$toasted.global.appError({ duration: 10000 });
          this.loadingForm = false;
          this.errorForm = 'error in loading form';
        })
        .then(async (responses) => {
          await this.$nextTick();
          if (!responses) {
            this.loadingForm = false;
            return;
          }

          await responses.forEach((response, index) => {
            if (response.data.error) {
              this.errorForm = 'error in loading form';
              return;
            }

            this[formRequests[2][index]] = response.data[formRequests[1][index]];
          });

          await this.$nextTick();
          this.loadingForm = false;
          this.errorForm = undefined;
        });
    },
  },
  async created() {
    // set data errors
    this.errors = { ...this.errors, ...this.companyForm };

    await this.loadForm();

    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
