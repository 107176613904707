<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Companies"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template v-slot:right-actions>
            <BaseButton
              text="Create Company"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              :to="{ name: 'createCompany' }"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <portal to="modal-header">
      <transition name="new" mode="out-in" appear>
        <div style="max-width: 700px;" class="md:tw-w-2/3 tw-mx-auto tw-relative tw-p-4">
          <BaseButton
            class="tw-uppercase tw-rounded-md tw-z-20 hover:tw-bg-gray-300 tw-flex tw-items-center"
            text="Back"
            icon
            :to="{ name: 'companies' }"
          >
            <span
              class="my-btn__icon tw-h-auto"
              style="height: auto;"
              slot="icon"
              v-html="leftNav"
            ></span>
          </BaseButton>
        </div>
      </transition>
    </portal>
    <portal to="modal-body" v-if="isSuperAdmin">
      <transition name="new" mode="out-in" appear>
        <CreateCompany
          class="tw-pb-16 tw-mx-auto tw-text-center"
          btn-class="tw-border-none tw-bg-app-deep-blue tw-py-3 tw-px-6 tw-text-white"
          @create-success="companyCreated"
        />
      </transition>
    </portal>

    <!-- COMPANIES LIST -->
    <div class="app-view-grid px-body">
      <div class="app-view-grid__col-74">
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorCompanies" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompanies"></p>
          </div>

          <ContentLoadingFullWidth
            v-else-if="loadingCompanies"
            class="tw-py-16 -tw-max-w-list tw-mx-auto"
            :list-length="4"
          />

          <template v-else>

            <div v-if="!companies.length" class="tw-mt-12">
              <!-- Goodies -->
              <p class="tw-py-16 tw-text-base tw-text-center opacity-54">
                There are 0 companies<br>
                Create one
              </p>
            </div>

            <!-- COMPANIES -->
            <div v-else>
              <div class="divider tw-w-full tw-border-t tw-border-app-black-11"></div>

              <ViewMore
                class="tw-w-full tw-font-sans"
                :list="companies"
                :limit="10"
              >

                <template v-slot="{ item: company, index }">
                  <div class="tw-flex transition-fast hover:tw-bg-app-white-32 tw-py-10" :key="`company-${index}`">
                    <!-- COMPANY LOGO -->
                    <div class="tw-flex-shrink-0 tw-flex-grow-0">
                      <UserPhoto
                        :photo="company.logo"
                        :user-name="{
                          name: company.company,
                        }"
                        logo
                        photo-size="tw-h-16 tw-w-16"
                        :class="[
                          'tw-rounded-10--force --force-children tw-bg-app-white-87',
                          { 'tw-shadow-app-sm': !(company.logo || '').length }
                        ]"
                      />
                    </div>

                    <div class="tw-pl-12 tw-flex-grow">
                      <!-- COMPANY DETAILS -->
                      <div class="tw-flex tw-justify-between">
                        <div class="tw--mt-1 tw-w-2/3">
                          <!-- COMPANY NAME -->
                          <h3 class="font-title tw-font-medium tw-text-2xl tw-leading-8 tw-text-black">
                            {{company.company}}
                          </h3>

                          <!-- COMPANY LOCATION -->
                          <div class="tw-inline-flex tw-items-center opacity-54 tw-text-black tw-w-1/3">
                            <span class="tw-mr-1">
                              <svg version="1.1" class="tw-w-4 tw-h-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                  y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                                <path d="M35.9,42.7c-7.4,0-13.4-6-13.4-13.4s6-13.4,13.4-13.4s13.4,6,13.4,13.4S43.3,42.7,35.9,42.7z M35.9,20.3
                                  c-4.9,0-8.9,4-8.9,8.9s4,8.9,8.9,8.9s8.9-4,8.9-8.9S40.8,20.3,35.9,20.3z M35.9,72c-2,0-3.8-0.9-5.1-2.2
                                  c-2.2-2.2-12.5-13.6-15.2-17.2l-0.2-0.4C9.5,44.3,5,38,5.7,28.4C6.6,13.2,19.1,0.7,34.5,0c0.5,0,2.5,0,3,0
                                  c15.2,0.7,28,13.2,28.8,28.4l0,0c0.7,9.8-4,15.9-9.6,23.7L56,52.5c-2.7,3.6-12.7,15-15.2,17.2C39.5,71.1,37.9,72,35.9,72z M35.9,4.5
                                  c-0.4,0-1.1,0-1.3,0C21.6,5.1,10.8,15.7,10,28.6c-0.4,8,3.4,13.4,8.9,20.8l0.2,0.4c2.2,3.1,12.3,14.3,14.8,16.8
                                  c0.4,0.4,1.3,0.9,2,0.9c0.4,0,1.1-0.4,1.8-0.9c2.5-2.5,12.5-13.6,14.8-16.8l0.2-0.4c5.4-7.4,9.4-12.7,8.9-20.8l0,0
                                  C60.9,15.7,50.2,4.9,37,4.5C37,4.5,36.3,4.5,35.9,4.5z"/>
                              </svg>
                            </span>

                            <h5
                              class="tw-text-sm leading-17 tw-truncate tw-uppercase"
                            >
                              <!-- CHANGE THIS BUG -->
                              {{ countryName(company.country) }}
                            </h5>
                          </div>

                          <h5 class="opacity-78 tw-mt-1 tw-text-black tw-text-sm leading-17">
                            created: {{ company.createAt | moment('Do MMMM YYYY') }}
                          </h5>

                          <h4 class="tw-mt-2 tw-text-app-orange tw-underline tw-text-base leading-19">
                            hosted at {{ company.website }}
                          </h4>
                        </div>

                        <div>
                          <!-- EDIT BUTTON -->
                          <BaseButton
                            text="view"
                            :title="`view ${company.company}`"
                            icon
                            :to="{ name: 'viewCompany', params: { id: company.id } }"
                            class="tw-mr-4"
                          >
                            <svg version="1.1" slot="icon" class="my-btn__icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                              <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
                                l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
                                M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
                                c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
                            </svg>
                          </BaseButton>
                        </div>
                      </div>

                      <!-- CONTACT DETAILS -->
                      <MyLicense :company-ref="company" class="tw-mt-12" />

                      <!-- LICENSE DETAILS -->
                      <div class="tw-mt-12 tw--ml-16 tw-pl-16 tw-rounded-l-32 tw-bg-app-deep-blue-11 tw-overflow-auto">
                        <p class="tw-text-sm leading-17 tw-text-app-deep-blue tw-font-bold opacity-54 tw-my-4 tw-uppercase">
                          License
                        </p>

                        <div class="tw-inline-flex tw-items-end">
                          <!-- LICENSE DETAILS -->
                          <!-- // TODO: MAKE THIS PART DYNAMIC -->
                          <div v-if="false" class="tw-bg-app-deep-blue tw-rounded-t-32 tw-px-8 tw-pt-2 tw-pb-4 tw--shadow-app">
                            <h4 class="tw-text-base tw-font-bold leading-19 tw-text-app-light-blue tw-mb-1">
                              Corporate License
                            </h4>

                            <!-- LICENSE DESCRIPTION -->
                            <p class="tw-text-xs leading-14 tw-text-app-light-blue opacity-78 tw-whitespace-pre-wrap">
                              1 year<br>
                              0 - 100 users<br>
                              Dashboard<br>
                            </p>
                          </div>

                          <!-- LICENSE STATUS -->
                          <div :class="[
                            'tw-rounded-t-32 tw-px-8 tw-pt-2 tw-pb-4 tw-ml-8 tw--shadow-app',
                            { 'tw-bg-green-500': Number(company.status) === 1 },
                            { 'tw-bg-red-500': Number(company.status) === 0 },
                            ]">
                            <!-- STATUS -->
                            <h4 class="tw-text-base tw-font-bold leading-19 tw-text-app-light-blue tw-mb-1">
                              Status: {{ Number(company.status) === 1 ? 'Healthy': 'Unverified' }}
                            </h4>

                            <!-- STATUS DESCRIPTION -->
                            <p v-if="Number(company.status) === 0" class="tw-text-xs leading-14 tw-text-app-light-blue">
                              Company not verified
                            </p>
                          </div>
                        </div>
                      </div>

                      <!-- ACTIONS -->
                      <div class="tw-flex tw-mt-8">
                        <div class="tw-flex-grow"></div>
                        <div>
                          <!-- <BaseButton
                            :text="`renew ${company.company.substring(0, 5)}...'s license`"
                            class="tw-bg-black tw-text-white tw-border-none tw-py-3 tw-px-8 tw-mr-6 hover:tw-shadow-app transition"
                          />
                          <BaseButton
                            :text="`cancel ${company.company.substring(0, 5)}...'s license`"
                            class="tw-bg-red-500 tw-text-white tw-border-none tw-py-3 tw-px-8 tw-mr-6 hover:tw-shadow-app transition"
                          /> -->
                        </div>
                      </div>
                    </div>

                  </div>

                  <div class="divider tw-w-full tw-border-t tw-border-app-black-11"></div>
                </template>

              </ViewMore>

            </div>

          </template>
        </transition>
      </div>
    </div>

  </AppView>
</template>

<script>
import CreateCompany from '@/views/Companies/_partials/CreateCompany.vue';
import MyLicense from '@/views/_partials/MyLicense.vue';
import ContentLoadingFullWidth from '@/components/ContentLoadingFullWidth.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import resolve from '@/modules/api/resolve';
import { apiGet } from '@/modules/apiHelper';

export default {
  name: 'CompaniesView',
  components: {
    CreateCompany,
    MyLicense,
    ContentLoadingFullWidth,
    UserPhoto,
  },
  data() {
    return {
      loadingCompanies: true,
      errorCompanies: undefined,
      companies: [],
      countries: [],
    };
  },
  watch: {
    $route() {
      this.reactToRoute();
    },
  },
  methods: {
    async getCompanies() {
      this.loadingCompanies = true;
      this.errorCompanies = undefined;

      const companies = await this.$store.dispatch('getCompanies', true);
      // console.log(companies);

      if (companies) {
        this.companies = companies || [];
      } else if (companies === false) {
        this.errorCompanies = 'Error in finding companies.';
      }

      this.loadingCompanies = false;
    },
    async companyCreated() {
      this.$toasted.success('Company created! Redirecting you to companies\' list', {
        duration: 4000,
      });

      // close modal
      this.$store.commit('openModal', [false]);

      await this.$nextTick();
      await this.$nextTick();

      // redirect to companies
      this.$router.push({
        name: 'companies',
      });
    },
    reactToRoute() {
      if (this.$route.name === 'createCompany') {
        // open modal
        this.$store.commit('openModal', [true, 'full-screen', false]);
      } else {
        // close modal
        this.$store.commit('openModal', [false]);
      }
    },
    countryName(countryCode) {
      // eslint-disable-next-line eqeqeq
      const country = this.countries.find((c) => c.id == countryCode);

      return country ? country.country_name : '';
    },
    async loadCountries() {
      this.countries = (await resolve(apiGet('countries', 5)) || {}).users || [];
    },
  },
  async created() {
    this.reactToRoute();

    await this.$nextTick();
    await this.$nextTick();

    await this.loadCountries();
    this.getCompanies();
  },
};
</script>

<style lang="postcss" scoped>
.tw-rounded-10--force {
  border-radius: 10px !important;
}
</style>
