<template>
  <form
    id="create-package"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card"
    action="/create-package"
    method="POST"
    @submit.prevent="onSubmit"
  >
    <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <h2 class="font-title tw-text-4xl tw-mb-1 tw-text-center tw-capitalize">{{packageData.id ? 'Edit' : 'Create'}} Package</h2>
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        input-type=""
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <TextareaGroup
        label="Description"
        instruct="Give a small description about the package"
        name="description"
        type="text"
        placeholder="description"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div>
    <div class="tw-w-1/3 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        label="Amount (cost)"
        instruct="in Ksh. (kenya shillings)"
        name="amount"
        type="number"
        placeholder="amount (cost)"
        input-classes="tw-p-3 tw-text-3xl font-title"
        v-model="form.amount"
        :error="errors.amount"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1 tw-flex tw-align-middle">
      <!-- duration -->
      <InputGroup
        label="Duration"
        name="duration"
        type="number"
        placeholder="duration"
        input-classes="tw-p-3"
        v-model="form.durationLength"
        :error="errors.durationLength"
      />
      <SelectGroup
        label="duration type"
        name="duration-type"
        nameKey="label"
        placeholder="group"
        input-classes="my-select--large tw-p-2 tw-bg-gray-300 tw-text-left"
        v-model="form.durationLengthType"
        :options="durationLengthTypes"
        :reduce="type => type.id"
        :clearable="false"
        :error="errors.durationLengthType"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="Pillars"
        name="pillars"
        nameKey="label"
        placeholder="pillar"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.pillarid"
        :options="pillars"
        :reduce="user => user.id"
        :clearable="false"
        :error="errors.pillarid"
      />
    </div>

    <div class="tw-w-full tw-text-center tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        class="tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-border-none tw-bg-app-deep-blue tw-py-3 tw-px-6 tw-text-white"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>
  </form>
</template>

<script>
import form from '@/modules/formHelper';

import InputGroup from '@/components/InputGroup.vue';
import TextareaGroup from '@/components/TextareaGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';

export default {
  name: 'EditPackage',
  components: {
    InputGroup,
    TextareaGroup,
    SelectGroup,
  },
  props: {
    packageData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      btnText: 'Save',
      btnDisabled: false,
      loadingForm: true,
      errors: {},
      durationLengthTypes: [
        {
          id: '1',
          label: 'days',
        },
        {
          id: '7',
          label: 'weeks',
        },
        {
          id: '30',
          label: 'months',
        },
        {
          id: '365',
          label: 'years',
        },
      ],
      pillars: [],
      form: {
        id: undefined,
        name: undefined,
        description: undefined,
        amount: undefined,
        durationLength: undefined, // int
        durationLengthType: undefined, // * 1(days)/30(months)/365(years)
        pillarid: undefined,
      },
    };
  },
  watch: {
    packageData() {
      console.log('packageData change. Refreshing form.');
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && form.isValidTextInput(this.form.description)
        // TODO: more
        && !this.loadingForm;
    },
    // calculated from the two duration inputs
    // eslint-disable-next-line func-names
    'form.duration': function () {
      return this.form.durationLength * Number(this.form.durationLengthType);
    },
  },
  methods: {
    resetForm() {
      this.form = {
        id: undefined,
        name: undefined,
        description: undefined,
        amount: undefined,
        durationLength: undefined,
        durationLengthType: undefined,
        pillarid: undefined,
      };
    },
    async loadForm() {
      this.loadingForm = true;

      await this.$nextTick();

      this.form.id = this.packageData.id;
      this.form.name = this.packageData.name;
      this.form.description = this.packageData.description;
      this.form.amount = this.packageData.amount;
      this.form.durationLength = this.packageData.duration;
      this.form.durationLengthType = '1';
      this.form.pillarid = this.packageData.pillarid;

      await this.$nextTick();

      // get pillars
      const pillars = await this.$store.dispatch('getPillarGroups', []);
      console.log(pillars);
      if (!pillars) {
        console.warn('loading form failed. pillar groups error');
        this.loadingForm = false;
        return;
      }

      this.pillars = pillars.map((pillar) => ({
        id: pillar.id,
        label: pillar.goal_group,
      }));

      this.loadingForm = false;
    },
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        duration: this['form.duration'],
      };

      const result = await this.$store.dispatch('savePackage', data);

      // console.log(result);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });

        this.$emit('package-created');
        this.resetForm();
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving package failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async mounted() {
    this.errors = { ...this.form };

    await this.$nextTick();

    this.loadForm();

    await this.$nextTick();
  },
};
</script>

<style>

</style>
