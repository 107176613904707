<template>
    <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Packages"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template v-slot:right-actions>
            <BaseButton
              text="Create a Package"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              @click="editPackage()"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto">
        <EditPackage
          class="tw-mx-auto"
          @package-created="packageCreated()"
          :package-data="packageData"
        />
      </div>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">
        <!-- FILTER COMPONENT -->
        <BaseCard class="tw-flex tw-flex-no-wrap tw-py-2 tw-px-4 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
          <div class="tw-inline-flex tw-items-center">
            <!--  -->
            <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
                c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
                l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
            </svg>
            <p class="leading-19 tw-ml-2">Filter</p>
          </div>
          <BaseInput
            v-model="filterConfig.search"
            type="text"
            placeholder="Search"
            input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
          />
          <!-- <BaseButton
            text="Filter"
            class="tw-ml-8 tw-bg-app-black-11 hover:tw-bg-blue-200 hover:tw-shadow-app transition-fast tw-py-3 tw-px-10 tw-flex-shrink-0 tw-border-none tw-flex-grow-0"
            @click="doFilter()"
          /> -->
        </BaseCard>

        <!-- PACKAGES -->
        <transition name="fade" mode="out-in" appear>

          <div v-if="errorPackages" class="tw-mt-12">
            <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorPackages"></p>
          </div>

          <ContentLoadingThreeGrid
            v-else-if="loadingPackages"
            class="tw-mt-16 -tw-max-w-list tw-mx-auto"
          />

          <div v-else-if="!packages.length" class="tw-my-12 tw-text-center">
            <p class="tw-py-8 tw-text-base tw-text-center">No packages.</p>
            <BaseButton
              text="Create a Package"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mx-body"
              @click="editPackage()"
            />
          </div>

          <ViewMore
            v-else
            class="tw-flex tw-flex-wrap stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-three tw-mt-12"
            :list="filteredPackages"
            :limit="20"
          >
            <template v-slot="{ item: aPackage, index }">
              <BaseCard
                :key="`pack-${index}`"
                class="tw-p-3 tw-border tw-rounded-10 tw-group"
              >
                <p class="font-title tw-text-xl tw-mb-0">
                  {{aPackage.name}}
                </p>
                <div class="tw-inline-flex tw-text-sm tw-align-middle tw-mb-4">
                  <p class="opacity-54">{{ aPackage.duration}} days</p>
                  <span class="opacity-31 tw-mx-1 tw-font-bold"> | </span>
                  <p class="opacity-54">ksh. {{ aPackage.amount}}</p>
                  <!-- <span class="opacity-78 tw-mx-1"> | </span> -->
                </div>

                <p class="tw-uppercase tw-text-xs tw-leading-none tw-font-bold opacity-31">Description</p>
                <p class="tw-text-sm tw-mb-2 opacity-78">
                  {{aPackage.description}}
                </p>


                <div class="tw-mt-4">
                  <BaseButton
                    text="edit"
                    class="opacity-18 group-hover:tw-opacity-100 group-hover:tw-shadow-app-lg transition-fast"
                    @click="editPackage(aPackage)"
                  />
                </div>
              </BaseCard>
            </template>
          </ViewMore>

        </transition>
      </div>
    </div>
  </AppView>
</template>

<script>
import EditPackage from '@/views/Packages/_partials/EditPackage.vue';
import BaseInput from '@/components/BaseInput.vue';

import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';

import filterMixin from '@/mixins/filter';

export default {
  name: 'PackagesView',
  components: {
    EditPackage,
    ContentLoadingThreeGrid,
    BaseInput,
  },
  mixins: [filterMixin],
  data() {
    return {
      packages: [],
      packageData: undefined,
      loadingPackages: true,
      errorPackages: undefined,
      filterConfig: {
        search: '',
        searchAt: [
          'name',
        ],
        dataName: 'packages',
      },
    };
  },
  computed: {
    filteredPackages() {
      return this.filteredList;
    },
  },
  methods: {
    async getPackages() {
      this.loadingPackages = true;
      this.errorPackages = undefined;
      await this.$nextTick();

      const packages = await this.$store.dispatch('getPackages');

      if (!packages) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingPackages = false;
        this.errorPackages = 'We could not get the list of packages. Please try again later';

        return false;
      }

      await this.$nextTick();

      this.packages = packages;

      await this.$nextTick();

      this.loadingPackages = false;
      this.errorPackages = undefined;

      return true;
    },
    async editPackage(packageData = {}) {
      this.packageData = packageData;
      await this.$nextTick();

      this.openEditPackageModal();
    },
    openEditPackageModal() {
      this.$store.commit('openModal', [true, 'full-screen']);
    },
    async packageCreated() {
      // close modal then get classes
      this.closeModal();

      await this.getPackages();
    },
  },
  async created() {
    await this.$nextTick();

    await this.getPackages();
  },
};
</script>

<style>

</style>
