<template>
  <form
    id="create-class"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card"
    action="/create-class"
    method="POST"
    @submit.prevent="onSubmit"
  >
    <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <h2 class="font-title tw-text-4xl tw-mb-1 tw-text-center tw-capitalize">{{classData.id ? 'Edit' : 'Create'}} Session</h2>
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <TextareaGroup
        label="Description"
        instruct="Give a small description about the session"
        name="description"
        type="text"
        placeholder="description"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div>

    <!-- // TODO: class start and end date inputs -->
    <!-- <p class="tw-text-xs leading-17 opacity-54 tw-mt-4"> {{ classTimeInfo }} </p> -->
    <div class="tw-flex tw-flex-wrap tw-mb-2 min-h-60">
      <div class="tw-w-1/2" style="padding-right: 5px;">
        <p class="tw-text-xs leading-17 opacity-54">Start time</p>
        <p class="tw-text-base leading-17" v-if="classStartTime || form.start">{{ (classStartTime || form.start) | moment('Do, MMM YYYY') }}</p>
      </div>
      <div class="tw-w-1/2" style="padding-left: 5px;">
        <p class="tw-text-xs leading-17 opacity-54">End time</p>
        <p class="tw-text-base leading-17" v-if="classEndTime || form.end">{{ (classEndTime || form.end) | moment('Do, MMM YYYY') }}</p>
        <p class="tw-text-left tw-text-xs tw-text-red-400 opacity-78" v-if="errors.end">{{ errors.end }}</p>
      </div>
    </div>

    <div class="tw-w-full tw-mb-8">
        <!-- [TODO] ADD LIMITS
          :limits="{min: lastWeek(), max: threeMonthsLater()}" -->
      <FunctionalCalendar
        placeholder="Start time"
        ref="class-time-range"
        v-model="classTimeData"
        :sunday-start="true"
        :date-format="'mm/dd/yyyy'"
        :is-date-range="true"
        :is-date-picker="false"
        :change-month-function="false"
        :change-year-function="false"
        :limits="{
          min: lastWeek(),
          max: threeMonthsLater()
        }"
        :day-names="['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']"
      />
    </div>

    <div class="tw-w-full tw-text-center tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        class="tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-border-none tw-bg-app-deep-blue tw-py-3 tw-px-6 tw-text-white"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>
  </form>
</template>

<script>
import { FunctionalCalendar } from 'vue-functional-calendar';

import form from '@/modules/formHelper';

import InputGroup from '@/components/InputGroup.vue';
import TextareaGroup from '@/components/TextareaGroup.vue';
import { toJsDate } from '@/modules/dataHelper';

export default {
  name: 'EditClass',
  components: {
    InputGroup,
    TextareaGroup,
    FunctionalCalendar,
  },
  props: {
    classData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      btnText: 'Save',
      btnDisabled: false,
      classTimeData: {},
      errors: {},
      form: {
        id: undefined,
        name: undefined,
        description: ' ',
        start: undefined,
        end: undefined,
      },
    };
  },
  watch: {
    classEndTime() {
      if (!this.isValidTimeRange(true)) {
        this.errors.end = 'invalid date range';
      } else {
        this.errors.end = undefined;
      }
    },
    classData() {
      console.log('classData change. Refreshing form.');
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && form.isValidTextInput(this.form.description)
        && this.isValidTimeRange(true);
    },
    classStartTime() {
      if (this.classTimeData.dateRange) {
        if (this.classTimeData.dateRange.start) {
          return toJsDate(this.classTimeData.dateRange.start, true);
        }
      }
      return undefined;
    },
    classEndTime() {
      if (this.classTimeData.dateRange) {
        if (this.classTimeData.dateRange.end) {
          return toJsDate(this.classTimeData.dateRange.end, true);
        }
      }
      return undefined;
    },
  },
  methods: {
    lastWeek() {
      return this.$moment(new Date()).subtract(6, 'days').format('YYYY-MM-DD');
    },
    threeMonthsLater() {
      return this.$moment(new Date()).add(3, 'months').format('YYYY-MM-DD');
    },
    isValidTimeRange(isStrict = false) {
      const validations = [false, false];
      const classTimeIsValid = (startTym, endTym) => {
        const classEndTym = this.$moment(toJsDate(endTym));
        const classStartTym = this.$moment(toJsDate(startTym));

        return classStartTym.isBefore(classEndTym);
      };

      // if computed prop returns a valid data
      if (this.classStartTime) {
        validations[0] = true;
      }

      // if computed prop returns a valid data
      if (this.classEndTime) {
        validations[1] = true;
      }

      // check range validity if both computed props return valid data
      if (this.classStartTime && this.classEndTime) {
        if (classTimeIsValid(this.classStartTime, this.classEndTime)) {
          return isStrict ? true : validations;
        }

        return isStrict ? false : validations;
      }

      return isStrict ? false : validations;
    },
    resetForm() {
      this.form = {
        id: undefined,
        name: undefined,
        description: '',
        start: undefined,
        end: undefined,
      };
    },
    loadForm() {
      this.form.id = this.classData.id;
      this.form.name = this.classData.class;
      this.form.description = this.classData.description;
      this.form.start = this.classData.start ? toJsDate(this.classData.start) : undefined;
      this.form.end = this.classData.end ? toJsDate(this.classData.end) : undefined;

      if (this.classTimeData.dateRange) {
        this.classTimeData.dateRange.start = this.classData.start || undefined;
        this.classTimeData.dateRange.end = this.classData.end || undefined;
      }
      //
    },
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      // TODO
      const now = new Date();
      const data = {
        ...(this.form),
        start: this.$moment(this.classStartTime).add(/* add now's seconds */ now.getHours(), 'h').format('YYYY-MM-DD HH:mm:ss'),
        end: this.$moment(this.classEndTime).add(/* add now's seconds */ now.getHours(), 'h').format('YYYY-MM-DD HH:mm:ss'),
      };


      // change start and end to date-string. dd-mm-yyyy format
      const result = await this.$store.dispatch('saveClass', data);

      // console.log(result);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });

        this.$emit('class-created');
        this.resetForm();
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving session failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async mounted() {
    this.errors = { ...this.form };

    await this.$nextTick();

    this.loadForm();

    await this.$nextTick();
  },
};
</script>

<style>

</style>
