<template>
  <form
    id="add-student"
    class="tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card"
    action="/add-student"
    method="POST"
    @submit.prevent="onSubmit"
  >
    <div class="tw-w-full tw-pt-6 tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <h2 class="font-title tw-text-xl tw-mb-1 tw-text-center tw-capitalize">Add Student</h2>
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <SelectGroup
        :class="{ '--loading-data': loadingUsers }"
        label="Mentors not in any session"
        name="mentors-not-in-any-session"
        nameKey="label"
        placeholder="group"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.userId"
        :options="users"
        :reduce="user => user.id"
        :clearable="false"
        :error="errors.groupId"
      />
    </div>

    <div class="tw-w-full tw-text-center tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        class="tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-border-none tw-bg-app-deep-blue tw-py-3 tw-px-6 tw-text-white"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>
  </form>
</template>

<script>
import SelectGroup from '@/components/SelectGroup.vue';

export default {
  name: 'AddStudent',
  components: {
    SelectGroup,
  },
  props: {
    classId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loadingUsers: true,
      errorUsers: undefined,
      btnText: 'Save',
      btnDisabled: false,
      users: [],
      errors: {},
      form: {
        classId: undefined,
        userId: undefined,
      },
    };
  },
  watch: {
    classId() {
      console.log('classId change');
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return !!this.form.classId && !!this.form.userId;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        classId: undefined,
        userId: undefined,
      };
    },
    async loadForm() {
      this.loadingUsers = true;
      this.errorUsers = undefined;

      await this.$nextTick();

      this.form.classId = this.classId;

      const users = await this.$store.dispatch('getStudentsNotInAnyClass');
      await this.$nextTick();

      if (!users) {
        const errorMessage = 'error in getting mentors.';
        this.loadingUsers = false;
        this.errorUsers = errorMessage;

        console.error('AddStudent::loadForm err', errorMessage);

        this.$toasted.global.appError({
          errorMessage: `${errorMessage} Please try again later`,
        });

        return;
      }

      this.users = users.map((c) => ({
        id: c.id,
        label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
      }));

      await this.$nextTick();

      this.loadingUsers = false;
      this.errorUsers = undefined;
    },
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      const result = await this.$store.dispatch('addStudent', [this.form.classId, this.form.userId]);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('student-added');
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Adding student failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async mounted() {
    this.errors = { ...this.form };

    await this.$nextTick();

    this.loadForm();

    await this.$nextTick();
  },
};
</script>

<style>

</style>
